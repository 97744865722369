var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reg"},[_c('div',{staticClass:"title"},[_vm._v("账号注册")]),_c('a-form',{staticClass:"registercon",attrs:{"form":_vm.register},on:{"submit":_vm.handleRegSubmit}},[_c('div',{staticClass:"reg-item"},[_c('div',{staticClass:"label"},[_vm._v("企业名称:")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'EnterpriseMC',{
              rules:[
              {required:true,message:'请输入企业名称'}
              ]
            }
            ]),expression:"[\n            'EnterpriseMC',{\n              rules:[\n              {required:true,message:'请输入企业名称'}\n              ]\n            }\n            ]"}],staticStyle:{"height":"36px"},attrs:{"size":"small","placeholder":"请输入企业名称","name":"EnterpriseMC"}})],1)],1),_c('div',{staticClass:"reg-item"},[_c('div',{staticClass:"label"},[_vm._v("企业类型:")]),_c('a-radio-group',{attrs:{"name":"Type"},model:{value:(_vm.Type),callback:function ($$v) {_vm.Type=$$v},expression:"Type"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 运营商 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 企业 ")])],1)],1),_c('div',{staticClass:"reg-item"},[_c('div',{staticClass:"label"},[_vm._v("手机号:")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'Phone',
            {
                rules: [
                {
                    required: true,
                    message: '请输入手机号'
                },
                {validator:_vm.PhoneValidator}
                ],
            } ]),expression:"[\n            'Phone',\n            {\n                rules: [\n                {\n                    required: true,\n                    message: '请输入手机号'\n                },\n                {validator:PhoneValidator}\n                ],\n            },\n            ]"}],staticStyle:{"height":"36px"},attrs:{"maxLength":11,"size":"small","placeholder":"请输入手机号","name":"Phone","type":"number"}})],1)],1),_c('div',{staticClass:"reg-item"},[_c('div',{staticClass:"label"},[_vm._v("验证码:")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'Code',{
              rules:[
              {required:true,message:'请输入验证码'}
              ]
            }
            ]),expression:"[\n            'Code',{\n              rules:[\n              {required:true,message:'请输入验证码'}\n              ]\n            }\n            ]"}],staticStyle:{"width":"224px","height":"36px"},attrs:{"size":"small","type":"text","placeholder":"请输入验证码","name":"Code"}})],1),_c('a-button',{staticStyle:{"width":"110px","margin-left":"14px"},attrs:{"type":"primary","disabled":_vm.disabled},on:{"click":_vm.getCode}},[_vm._v(" "+_vm._s(_vm.valiBtn)+" ")])],1),_c('div',{staticClass:"reg-item",staticStyle:{"margin-bottom":"0"}},[_c('div',{staticClass:"label"},[_vm._v("密码:")]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'Password',{
              rules:[
              {required:true,message:'请输入密码'}
              ]
            }
            ]),expression:"[\n            'Password',{\n              rules:[\n              {required:true,message:'请输入密码'}\n              ]\n            }\n            ]"}],staticStyle:{"height":"36px","width":"350px"},attrs:{"size":"small","type":"password","autocomplete":"new-password","placeholder":"请输入密码","name":"Password"}})],1)],1),_c('div',{staticClass:"read-reg"},[_c('a-checkbox',{staticStyle:{"color":"#ffffff","margin":"10px 8px","justify-content":"flex-start"},attrs:{"size":"large"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}}),_vm._v(" 我已阅读并同意"),_c('span',{on:{"click":_vm.onUserProtocol}},[_vm._v("《用户协议》")]),_vm._v("和"),_c('span',{on:{"click":_vm.onPrivacyProtocol}},[_vm._v("《隐私协议》")])],1),_c('a-button',{staticStyle:{"width":"140px","height":"36px","margin":"0 auto"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.regBtnText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="forgetpass">
        <div class="form_head">
            <div>找回密码</div>
            <div style="cursor: pointer" @click="accountLogin()">账号登录</div>
        </div>
        <a-form
            :form="formpass"
            class="myforget"
             @submit="handlePassSubmit"
        >
        <a-form-item class="lable-colorF">
          <a-input
            style="height: 36px"
            :maxLength="11"
            size="small"
            placeholder="请输入手机号"
            name="Phone"
            type="number"
            has-feedback=""
            v-decorator="[
              'Phone',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入手机号',
                    whitespace: true
                  }
                ],
              },
            ]"
          />
        </a-form-item>
            <a-form-item class="codes">
            <a-input
                style="height: 36px;width:180px;"
                size="small"
                placeholder="填写验证码"
                name="Code"
                v-decorator="[
                'Code',
                {
                    rules: [
                    {
                        required: true,
                        message: '填写验证码',
                    },
                    ],
                },
                ]"
            />
            <a-button
                style="width: 110px;margin-left:14px"
                type="primary"
                :disabled="disabled"
                @click="getCode"
                >
                {{valiBtn}}
            </a-button>
            </a-form-item>
        <a-form-item class="lable-colorF">
          <a-input-password
            style="height: 36px"
            size="small"
            type="password"
            placeholder="请输入新密码(长度为8-18个字符)"
            autocomplete="new-password"
            name="Password"
            v-decorator="[
              'Password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入新密码',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
         <a-form-item class="lable-colorF">
          <a-input-password
            style="height: 36px"
            size="small"
            placeholder="确认密码(长度为8-18个字符)"
            name="QRPassword"
            type="password"
            v-decorator="[
              'QRPassword',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入确认密码',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-button
          style="width: 306px;height:36px;"
          type="primary"
          html-type="submit"
        >
          {{submitText}}
        </a-button>
        </a-form>
    </div>
</template>
<script>
import { http,httpGet } from "../../utils/Http.js";
export default {
    data(){
        return {
            forget:{
                code:'',
                password1:''
            },
            formLayout: "horizontal",
            formpass: this.$form.createForm(this, {
                name: "passrule",
            }),
            valiBtn: "获取验证码",
            disabled: false,
            submitText:'提交',
            passHeight:363
        }
    },
    methods:{
        //验证手机号
        PhoneValidator(rule,value,callback){
          console.log(value);
          let reg=/^[1][3,4,5,7,8,9][0-9]{9}$/;
          if(!reg.test(value)){
                callback('手机号格式不正确')
          }
          callback()
        },
        //账号登陆
        accountLogin(){
            this.$emit('accountLogin');
        },
        //提交
        handlePassSubmit(e){
            let me=this;
            e.preventDefault();
            this.formpass.validateFields((err, values) => {
              if (!err) {
                let reg=/^1\d\d\d\d\d\d\d\d\d\d$/;
                if(!reg.test(values.Phone)){
                  me.$message.error('手机号格式不正确');
                  return;
                }
                me.submitText='正在提交';
                http({
                url: "/data/hb/reg/HJJC_PasswordReset.aspx",
                data: {
                    Phone: values.Phone,
                    Code:values.Code,
                    Password:values.Password,
                    QRPassword:values.QRPassword
                },
                success(obj) {
                  me.submitText='提交';
                  me.$message.success('密码修改成功！');
                  me.$emit('accountLogin');
                },
                  failure(obj) {
                    me.submitText='提交';
                  },
                  me,
                });
              }
            });
        },
         //获取验证码 并只验证手机号 是否正确
        getCode() {
            var me=this;
            this.formpass.validateFields(['Phone'],(err, values) => {
              if (!err) {
                let reg=/^1\d\d\d\d\d\d\d\d\d\d$/;
                if(!reg.test(Number(values.Phone))){
                  me.$message.error('手机号格式不正确');
                  return;
                }
                http({
                url: "/data/hb/reg/SendVerificationCode.aspx",
                data: {
                    Type: '2',
                    Phone: values.Phone
                },
                success(obj) {
                  if (obj.result==0) {
                      me.$message.success('验证码已发送，注意查看短信！');
                      me.tackBtn(); //验证码倒数60秒
                  }
                },
                  failure(obj) {},
                  me,
                });
              }
            });
        },
        tackBtn() {
            //验证码倒数60秒
            let time = 60;
            let timer = setInterval(() => {
                if (time == 0) {
                clearInterval(timer);
                this.valiBtn = "获取验证码";
                this.disabled = false;
                } else {
                this.disabled = true;
                this.valiBtn = time + "秒后重试";
                time--;
                }
            }, 1000);
        }
      }
}
</script>
<style>
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
 
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<style lang="scss" scoped>
.forgetpass{
    width:340px;
    // height: 363px;
    background: rgba(255, 255, 255,1);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.12);
     .form_head {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8;
        padding: 0 17px 0 22px;
    }
    .form_head div:nth-child(1) {
        color: #333333;
        font-size: 20px;
    }
    .form_head div:nth-child(2) {
        color: #666666;
        font-size: 14px;
    }
    .myforget{
        padding: 20px 17px;
        .codes{
            height: 32px;
            width:306px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .ant-form-item{
            margin-bottom: 17px;
        }
    }
}
</style>
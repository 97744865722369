<template>
    <div class="reg">
        <div class="title">账号注册</div>
        <a-form
            :form="register"
            class="registercon"
             @submit="handleRegSubmit"
        >
        <div class="reg-item">
            <div class="label">企业名称:</div>
            <a-form-item>
            <a-input
                style="height: 36px"
                size="small"
                placeholder="请输入企业名称"
                name="EnterpriseMC"
                v-decorator="[
                'EnterpriseMC',{
                  rules:[
                  {required:true,message:'请输入企业名称'}
                  ]
                }
                ]"
            />
            </a-form-item>
        </div>
        <div class="reg-item">
            <div class="label">企业类型:</div>
            <a-radio-group name="Type" v-model="Type">
                <a-radio :value="1">
                运营商
                </a-radio>
                <a-radio :value="2">
                企业
                </a-radio>
            </a-radio-group>
        </div>
        <div class="reg-item">
            <div class="label">手机号:</div>
            <a-form-item>
            <a-input
                style="height: 36px"
                :maxLength="11"
                size="small"
                placeholder="请输入手机号"
                name="Phone"
                type="number"
                v-decorator="[
                'Phone',
                {
                    rules: [
                    {
                        required: true,
                        message: '请输入手机号'
                    },
                    {validator:PhoneValidator}
                    ],
                },
                ]"
            />
            </a-form-item>
        </div>
        <div class="reg-item">
            <div class="label">验证码:</div>
            <a-form-item>
            <a-input
                style="width:224px;height: 36px"
                size="small"
                type="text"
                placeholder="请输入验证码"
                name="Code"
                v-decorator="[
                'Code',{
                  rules:[
                  {required:true,message:'请输入验证码'}
                  ]
                }
                ]"
            />
            </a-form-item>
            <a-button
                style="width: 110px;margin-left:14px"
                type="primary"
                :disabled="disabled"
                @click="getCode"
                >
                {{valiBtn}}
            </a-button>
        </div>
        <div class="reg-item" style="margin-bottom:0">
            <div class="label">密码:</div>
            <a-form-item>
            <a-input-password
                style="height: 36px;width:350px;"
                size="small"
                type="password"
                autocomplete="new-password"
                placeholder="请输入密码"
                name="Password"
                v-decorator="[
                'Password',{
                  rules:[
                  {required:true,message:'请输入密码'}
                  ]
                }
                ]"
            />
            </a-form-item>
        </div>
        <div class="read-reg">
          <a-checkbox
            style="
              color: #ffffff;
              margin: 10px 8px;
              justify-content: flex-start;
            "
            v-model="checked"
            size="large"
          >
          </a-checkbox>
          我已阅读并同意<span @click="onUserProtocol">《用户协议》</span>和<span  @click="onPrivacyProtocol">《隐私协议》</span>
        </div>
        <a-button
          style="width: 140px;height:36px;margin:0 auto"
          type="primary"
          html-type="submit"
        >
          {{regBtnText}}
        </a-button>
        </a-form>
    </div>
</template>
<script>
import { http,httpGet } from "../../utils/Http.js";
export default {
    data(){
        return {
            register: this.$form.createForm(this, {
                name: "registerrule",
            }),
            valiBtn: "获取验证码",
            disabled: false,
            checked:false,
            regBtnText:'立即注册',
            Type:1
        }
    },
    methods:{
         //验证手机号
        PhoneValidator(rule,value,callback){
          console.log(value);
          let reg=/^1[3|4|5|7|8][0-9]\d{8}$/;
          if(!reg.test(value)){
              callback('手机号格式不正确')
            }
          callback()
        },
        //立即注册
        handleRegSubmit(e){
           e.preventDefault();
           var me=this;
           this.register.validateFields((err, values) => {
                if (!err) {
                  if(!me.checked){
                     me.$message.error('注册前请先阅读并同意用户协议和隐私协议');
                     return;
                  }
                  me.regBtnText='正在注册';
                    http({
                    url: "/data/hb/reg/HJJC_Register.aspx",
                    data: {
                        EnterpriseMC: values.EnterpriseMC,
                        Type: me.Type,
                        Phone: values.Phone,
                        Code: values.Code,
                        Password: values.Password
                    },
                    success(obj) {
                      if(Number(me.Type)==1){
                          if(obj.Data&&obj.Data.length>0){
                            //提交供应商设置接口
                            me.HJJC_OperatorSet(obj.Data[0]);
                          }
                      }else{
                        me.regBtnText='立即注册';
                        me.$message.success('注册成功！');
                        me.$emit('handleRegSubmit');
                      }
                    },
                      failure(obj) {
                            me.regBtnText='立即注册';
                      },
                      me,
                    });
                }
            });
            return;
        },
        //供应商设置
        HJJC_OperatorSet(data){
          var me=this;
          http({
          url: "/data/hb/HJJC_OperatorSet.aspx",
          data: {
              CKM: data.ckm,
              Operator_MC: data.Operator_MC,
              phone:data.phone
          },
          success(obj) {
            me.regBtnText='立即注册';
            me.$message.success('注册成功！');
            me.$emit('handleRegSubmit');
          },
            failure(obj) {
              me.regBtnText='立即注册';
            },
            me,
          });
        },
        //用户协议
        onUserProtocol(){
            this.$emit('onUserProtocol')
        },
        //隐私协议
        onPrivacyProtocol(){
            this.$emit('onPrivacyProtocol')
        },
        //获取验证码 并只验证手机号 是否正确
        getCode() {
          var me=this;
            this.register.validateFields(['Phone','EnterpriseMC'],(err, values) => {
                console.log(err);
                    if (!err) {
                    http({
                    url: "/data/hb/reg/SendVerificationCode.aspx",
                    data: {
                       Type: '1',
                        Phone: values.Phone,
                        qymc: values.EnterpriseMC
                    },
                    success(obj) {
                      console.log(obj);
                      if (obj.result==0) {
                         me.$message.success('验证码已发送，注意查看短信！');
                         me.tackBtn(); //验证码倒数60秒
                      }
                    },
                      failure(obj) {},
                      me,
                    });
                  }
            });
        },
        tackBtn() {
        //验证码倒数60秒
        let time = 60;
        let timer = setInterval(() => {
            if (time == 0) {
            clearInterval(timer);
            this.valiBtn = "获取验证码";
            this.disabled = false;
            } else {
            this.disabled = true;
            this.valiBtn = time + "秒后重试";
            time--;
            }
        }, 1000);
        },
    }
}
</script>
<style>
.ant-input-affix-wrapper .ant-input{
    height: 36px;
    font-size: 14px;
}
input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
 
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<style lang="scss" scoped>
.reg{
    width:100%;
    background: #fff;
    .title{
        height: 70px;
        display: flex;
        align-items: center;
        font-size: 22px;
        color:#333;
        justify-content: center;
        border-bottom: 1px solid #e8e8e8;
    }
    .registercon{
        width:430px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        .reg-item{
            display:flex;
            flex-direction:row;
            height: 36px;
            align-items: center;
            margin-bottom: 20px;
            .label{
                width:70px;
                text-align: right;
                margin-right: 10px;
            }
        }
        .ant-form-item{
            display: flex;
            margin: 0;
            input{
                font-size: 14px;
                width:350px;
            }
        }
    }
    .registercon .ant-form-item .ant-col{
        width:70px;
    }
    .read-reg{
        display: flex; 
        padding-left:70px;
        font-size: 12px;
        align-items: center;
        color:rgba(61, 61, 61, 1);
        span{
            color:rgba(23, 92, 251, 1);
            cursor: pointer;
        }
    }
}
</style>
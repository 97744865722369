<template>
  <div id="login">
    <div class="title">
      <img class="login-l-b" src="/pics/login-l-b.png" alt="" />
    </div>
    <div class="form-login">
      <div class="title">
        <img :src="url" class="logo" />
        <p>{{ title }}</p>
      </div>
      <!-- 登录部分-->
      <a-form
        class="wrapper"
        :form="form"
        @submit="handleSubmit"
        layout="horizontal"
        v-if="state==1"
      >
        <div style="width: 200px; color: #fff">手机号/账号</div>
        <a-form-item class="lable-colorF">
          <a-input
            style="height: 32px"
            :maxLength="11"
            size="large"
            label="手机号/账号"
            placeholder="手机号/账号"
            name="user"
            v-decorator="[
              'user',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入用户名',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div style="width: 200px; color: #fff">密码</div>
        <a-form-item>
          <!-- :maxLength="6" -->
          <a-input-password
            style="height: 32px"
            size="large"
            placeholder="密码"
            :visibilityToggle="false"
            name="password"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入用密码',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <div style="display: flex; width: 200px">
          <a-checkbox
            style="
              color: #ffffff;
              margin: 10px 0px;
              justify-content: flex-start;
            "
            v-model="checked"
            size="large"
          >
            记住密码
          </a-checkbox>
        </div>
        <a-button
          style="width: 200px"
          :disabled="disabled"
          type="primary"
          html-type="submit"
        >
          登录
        </a-button>
        <div class="register">
          <!-- <div  @click="onRegister()">注册</div> -->
          <div>&nbsp;</div>
          <div @click="onForgetPass()">忘记密码</div>
        </div>
      </a-form>
      <!-- 忘记密码-->
      <forgetpassword v-if="state==3" @accountLogin="accountLogin()"></forgetpassword>
      <!-- 注册-->
      <register v-if="state==2" @onPrivacyProtocol="onPrivacyProtocol()" @onUserProtocol="onUserProtocol()" @handleRegSubmit="handleRegSubmit()"></register>
    </div>
    <div class="placeholder"></div>
    <div class="tips">
      <div style="margin-bottom:10px;">建议使用内核为谷歌的浏览器，显示器分辨率1920*1080更佳</div>
      <div class="myprotocol" v-if="state==1||state==3">
        <span style="margin-right:20px" @click="onUserProtocol">用户协议</span>
        <span style="margin-right:20px"  @click="onPrivacyProtocol">隐私协议</span>
        <span  @click="onICP">豫ICP备18016332号</span>
      </div>
    </div>
    <a-modal v-model="userProtocol" class="mymodal" width="630px" centered title="用户协议" cancelText="关闭">
      <div class="protocol_con" v-html="protocol.user"></div>
    </a-modal>
    <a-modal v-model="privacyProtocol" class="mymodal" width="630px" centered title="隐私协议" cancelText="关闭">
      <div class="protocol_con" v-html="protocol.privacy"></div>
      <div></div>
    </a-modal>
    <!-- 扫码下载APK -->
    <div id="scancode_box">
      <div
        class="scancode_btn"
        id="android_scancode"
        @mouseover="StopRoll('安卓', '0')"
        @mouseleave="UpRoll"
      >
        <img :src="require('@/assets/img/login/android_icon.png')" alt="" />
      </div>
      <div
        class="scancode_btn"
        id="ios_scancode"
        @mouseover="StopRoll('苹果', '80px')"
        @mouseleave="UpRoll"
      >
        <img :src="require('@/assets/img/login/ios_icon.png')" alt="" />
      </div>
      <div
        class="scancode_btn"
        id="ios_scancode"
        @mouseover="StopRoll('后台', '160px')"
        @mouseleave="UpRoll"
      >
        <a style="color:#fff" title="管理后台入口" href="http://gl.allspark.top" target="_blank"><img :src="require('@/assets/img/login/plat.png')" alt="" /></a>
      </div>
      <div
        class="scancode_hover_box"
        v-if="scancodeboxshow"
        :style="'top:' + topnum"
      >
        <img
          :src="require('@/assets/img/login/android_erweima.png')"
          id="android_img"
          alt=""
          v-if="showimg == '安卓'"
        />
        <img
          :src="require('@/assets/img/login/ios_erweima.png')"
          id="ios_img"
          alt=""
          v-if="showimg == '苹果'"
        />
        <div v-if="showimg == '后台'" style="width:120px;">管理后台入口</div>
        <p v-else>{{ modeltext }}下载</p>
      </div>
    </div>
  </div>
</template>

<script>
import { http,httpGet } from "../utils/Http.js";
import forgetpassword from "@/components/login/forgetpassword"
import register from "@/components/login/register"
const axios = require("axios");

//base64 md5
import { Base64 } from "js-base64";
import CryptoJS from "crypto-js";
// CryptoJS key
const key = "qusukeji";

export default {
  name: "Login",
  components:{
    forgetpassword,
    register
  },
  data() {
    return {
      state:1,//1表示登录2、注册3、忘记密码
      title:
        localStorage.getItem("corpName") || "生态环境监测数据综合管理平台系统",
      formLayout: "horizontal",
      form: this.$form.createForm(this, {
        name: "coordinated",
      }),
      disabled: false,
      url:
        localStorage.getItem("headImg") ||
        require("../assets/img/login/005.png"),
      checked: false,

      promptText: "建议使用内核为谷歌的浏览器，显示器分辨率1920*1080更佳", //提示文字

      scancodeboxshow: false, //扫码下载apk的框 是否显示
      showimg: "", //显示那张二维码
      topnum: "0", //扫码框显示位置
      modeltext: "", //安卓下载或苹果下载
      userProtocol:false,
      privacyProtocol:false,
      protocol:{
        user:'',
        privacy:''
      }
    };
  },

  mounted() {
    //20220401暂时隐藏用户协议和隐私协议的读取
    // this.readFile("/data/protocol/user.txt",1);
    // this.readFile("/data/protocol/privacy.txt",2);
  },

  methods: {
    //立即注册
    handleRegSubmit(){
      this.state=1;
    },
    //用户注册
    onRegister(){
      this.state=2;
    },
    //账号登陆
    accountLogin(){
      this.state=1;
    },
    //忘记密码
    onForgetPass(){
      this.state=3;
    },
    readFile(fileUrl,type){
      let me=this;
       httpGet({
        url: fileUrl,
        success(obj) {
          obj = obj.replace(/(\r\n)|(\n)/g, "<br/>");
          //替换所有的空格（中文空格、英文空格都会被替换）
          obj = obj.replace(/\s/g, "&nbsp;");
          if(type==1){
            me.protocol.user=obj;
          }else{
            me.protocol.privacy=obj;
          }
        },
        failure(obj) {
          //console.log(JSON.stringify(obj));
        }
      });
    },
    //用户协议
    onUserProtocol(){
      let me=this;
      me.userProtocol=true
    },
    //隐私协议
    onPrivacyProtocol(){
      this.privacyProtocol=true;
    },
    //ICP备案
    onICP(){
      window.open('http://beian.miit.gov.cn/','newwindow');
    },
    /**
     * 登录按钮的点击事件
     */
    handleSubmit(e) {
      var me = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //手机号验证
          // var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
          // if (!myreg.test(values.user)) {
          // }

          // // console.log(values);
          me.HJJC_LOGIN(values);
        }
      });
    },

    /**
     * 登录的ajax请求
     */
    HJJC_LOGIN(values) {
      var me = this;
      http({
        url: "/data/q30/HJJC_LOGIN.aspx",
        data: {
          Phone: values.user,
          PWD: values.password,
          LX: "3",
        },
        success(obj) {
          // // console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            var loggedIn = obj.Data[0];
            console.log(loggedIn);
            console.log( JSON.stringify(loggedIn));
            localStorage.removeItem("loggedIn", null);
            localStorage.setItem("loggedIn", JSON.stringify(loggedIn));
            // let pwd=values.password;
            // let base64Pwd = Base64.encode(pwd);
            // // Encrypt 加密
            // let cryptoJsPwd = CryptoJS.AES.encrypt(base64Pwd, key).toString();
            // localStorage.setItem("loggedInfo", cryptoJsPwd);
            me.$store.commit("setLoggedIn", loggedIn);

            me.checkedPwd(values.user, values.password);

            me.$router.push("/main/home");
          }
        },
        failure(obj) {},
        me,
      });
    },

    /**
     * 记录账号密码
     */
    checkedPwd(username, pwd) {
      // console.log(username, pwd);
      // 记住密码进行cookie存储和密码加密
      if (this.checked) {
        // base64 加密
        let base64Pwd = Base64.encode(pwd);
        // Encrypt 加密
        let cryptoJsPwd = CryptoJS.AES.encrypt(base64Pwd, key).toString();
        // 账号密码保存天数
        this.setCookie(username, cryptoJsPwd, 7);
      } else {
        // 清空
        this.clearCookie();
      }
    },

    // 设置cookie
    setCookie(c_name, c_pwd, exdays) {
      // console.log(c_name, c_pwd, exdays);
      var exdate = new Date(); // 获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 保存的天数
      // 字符串拼接cookie
      window.document.cookie =
        "username" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "password" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    // 读取cookie
    getCookie: function () {
      let me = this;
      if (document.cookie.length > 0) {
        //checked为true
        this.checked = true;
        var arr = document.cookie.split("; ");
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("=");
          if (arr2[0] == "username") {
            let user = arr2[1];
            me.$nextTick(() => {
              me.form.setFieldsValue({
                user: user,
              });
            });
          } else if (arr2[0] == "password") {
            // Decrypt 解密
            let bytes = CryptoJS.AES.decrypt(arr2[1], key);
            let originalText = bytes.toString(CryptoJS.enc.Utf8);
            // base64解密
            let pwd = Base64.decode(originalText);
            me.$nextTick(() => {
              me.form.setFieldsValue({
                password: pwd,
              });
            });
          }
        }
      }
    },
    // 清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1); // 修改2值都为空，天数为负1天就好了
    },

    //移入时触发
    StopRoll(val, num) {
      this.scancodeboxshow = true;
      this.showimg = val;
      this.modeltext = val;
      this.topnum = num;
    },
    //移出时触发
    UpRoll() {
      this.scancodeboxshow = false;
      this.showimg = "";
    },
  },

  /**
   * 生命钩子函数
   */
  created() {
    // 获取cookie
    this.getCookie();
  },
};
</script>
<style lang="scss">
.mymodal{
  .ant-modal-title{
    text-align: center;
  }
  .ant-modal-body{
    padding: 20px !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .ant-modal-footer{
    text-align: center;
  }
  .ant-btn-primary{
    display: none;
  }
}
</style>
<style lang="scss" scoped>
#login {
  height: 100%;
  width: 100%;
  min-width: 1200px;
  color: #181818;
  background-color: #0d62fc;
  background-image: linear-gradient(#0a59fb, #126bfd);
  // background: url(../assets/img/login/001.png) no-repeat center center;
  background-size: 140% 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow:hidden;
  .bgi {
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0px;
  }
  .login-l-b {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 9.3rem;
    height: 4.65;
  }

  .form-login {
    // width: 900px;
    // height: 500px;
    width: 1200px;
    height: 600px;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1.4rem;
    .title {
      display: flex;
      color: #fff;
      font-weight: bold;
      align-items: center;
      font-size: 28px;
      line-height: 39px;
      margin-bottom: 30px;

      .logo {
        width: 48px;
        height: 39px;
        margin-right: 11px;
      }
    }
    .wrapper {
      width: 470px;
      height: 439px;
      display: flex;
      align-items: center;
      flex-direction: column;
      // background: url(/pics/login-wrap.png) no-repeat center center;
      background: url(/pics/login-wrap.png) no-repeat center center;
      background-size: 140% 140%;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-form-item {
        margin: 0px !important;
      }
    }
  }

  .placeholder {
    flex: 1;
  }

  .tips {
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    z-index: 10;
    position: fixed;
    bottom: 1px;
    span{
      cursor: pointer;
    }
  }
}
.myprotocol{
  margin-bottom: 20px;
}
@media only screen and (max-width: 1366px) and (min-width: 100px) {
  .myprotocol{
    margin-bottom: 35px;
  }
}

//用户协议
.protocol_con{
  width:607px;
  height: 560px;
  overflow: hidden;
  overflow-y: scroll;
  line-height: 30px;
  padding-right: 10px;
}
@media only screen and (max-width: 1366px) and (min-width: 100px) {
  .protocol_con{
    height: 465px;
  }
}
.protocol_con::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

.protocol_con::-webkit-scrollbar-thumb {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 3px;
}

//手机apk下载样式
#scancode_box {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -65px;
  .scancode_btn {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 3px 0 0 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .scancode_hover_box {
    background: #fff;
    padding: 10px;
    text-align: center;
    position: absolute;
    right: 64px;
    top: 0;
    img {
      width: 126px;
    }
    p {
      margin: 0;
      font-size: 16px;
      margin-top: 5px;
    }
  }
}
//注册部分
.register{
  width: 200px;
  margin:10px auto;
  font-size: 14px;
  color:#fff;
  display: flex;
  justify-content: space-between;
}
.register div{
  cursor: pointer;
}
</style>
